<template>
	 <v-app>
        <v-card width="300" outlined class="mx-auto my-auto" :loading="btnLoading">
            <v-container class="my-auto">
                <v-row>
                    <v-card-title>
                        <v-icon large left>mdi-sigma</v-icon>
                        <span class="title font-weight-light">Forget Password</span>
                    </v-card-title>
                </v-row>

                <v-row>
                    <v-form ref="loginForm" autocomplete="chrome-off">
                        <v-col lg="12" md="12" xs="12" cols="12">
                            <v-text-field  label="email" solo flat prepend-inner-icon="mdi-email" type="email" v-model="email" />
                        </v-col>
                    </v-form>
                </v-row>

                <v-row justify="center"></v-row>

                <v-row justify="center">
                    <v-col lg="12" md="12" xs="12" cols="12">
                        <v-alert type="success" dense v-if="forgetStatus == 1">
                        	Please find your reset password link in your mailbox.
                        </v-alert>
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-col lg="12" md="12" xs="12" cols="12" align="center">
                        <v-btn block color="info" :loading="btnLoading" @click="forgetPassword">
                        	SUBMIT
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-card-text class="mb-5 pb-0">
                        <p class="text-center font-weight grey--text overline mb-0">
                            <a @click="redirectSignin">Back to Sign In</a>
                        </p>
                    </v-card-text>
                </v-row>
            </v-container>
        </v-card>
    </v-app>
</template>
<script>
import * as apiForgetPassword from "@components/api/authpk/forget-password";
import { snackbar } from "@components/mixins/snackbar";

export default {
    props: {},
    mixins: [snackbar],
    data () {
        return {
            email: "",
            btnLoading: false,
            forgetStatus: -1
        };
    },
    methods: {
        /**
		 * Forget password post method
		 * @return {[type]} [description]
		 */
        forgetPassword () {
            const self = this;
            if (self.email == null || self.email == "" || self.email == undefined) {
                self.snackBarDanger("Please fill in email");
                return;
            }
            const params = { email: self.email, url: window.location.origin };
            self.forgetStatus = 0;
            self.btnLoading = true;
            apiForgetPassword.create(params).then(res => {
                // self.snackBarSuccess("Update Successfully");
                setTimeout(() => {
                    self.btnLoading = false;
                    self.forgetStatus = 1;
                }, 1000);
            });
        },
        /**
		 * Go back to signin page
		 * @return {[type]} [description]
		 */
        redirectSignin () {
            this.$router.push({ name: "Signin" });
        }
    },
    mounted () {}
};
</script>
